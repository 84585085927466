import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { useAppDispatch, useAppSelector } from './hooks';
import { setCurrentLocation } from '@store/reducers/session.reducer';
import { makeStyles } from '@material-ui/core/styles';
import Login from '@src/Pages/logins/Login';
import DebugPage from '@pages/debug';
import { SinglePage, LandingPage1, LandingPage2, LandingPage3 } from '@pages/Landings';
import LoginNew from '@src/Pages/logins/loginNew';
import Main from '@pages/Main/MainPage';
import LoginSocial from '@src/Pages/logins/LoginSocial';
import { Room } from '@pages/PrivateLobby/privateRoom';
import OrientalModal from '../components/Modals/OrientalModal';
import { AppService } from '@services/appService';
import { ErrorBoundary } from './errorBoundary';
import { MetaWrapper } from '@components/AppWrappers/MetaWrapper';
import { DeepLink } from '@pages/misc/deepLink';
import { selectPlayerWeak, selectHasPlayer, setLocalPlayer } from '@store/reducers/player.reducer';
import { AUTH_CALLBACK_LOCATION, USER_COOKIE } from '@src/features/auth/auth.config';
import { SecuredLogic } from '@src/features/auth/components/secured';
import { BackendAuth } from '@src/features/auth/components/backendAuthDebug';
import { AUTH_TYPE, LoginType } from '@src/shared/common';
import { CoinsPage } from '@src/Pages/coins/coins';
import { Link } from '@src/Pages/misc/link';
import RoomsPage from '@src/Pages/roomsPage/roomsPage';
import { useAuth } from 'oidc-react';
import { authSignInSilent } from '@src/features/auth/auth.helper';
import { ProfilePage } from '@src/Pages/profile/profile';
import { MenuPages } from './pageWrapper';
import PandaHome from '@pc/pages/home/PandaHome';
import { PandaLogin } from '@src/Pages/logins/Login/PandaLogin';
import { Cabinet } from '@src/Pages/logins/Login/Cabinet';
// import { MintPage } from '@src/features/solana/mintPage';

const containerStyle = makeStyles((theme) => ({
  maxWidthMd: {
    maxWidth: '100%',
    height: '100%',
    flexDirection: 'column',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
      display: 'none',
    },
  },
}));

const TrackingRoute: React.FC<RouteProps> = (props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentLocation(props.location?.pathname || ''));
  });

  return <Route {...props} />;
};

interface CustomRouteProps extends RouteProps {
  shouldMetaWrap?: boolean;
  autologin?: boolean;
  loginType?: LoginType;
}

const PrivateRoute: React.FC<CustomRouteProps> = ({
  children,
  shouldMetaWrap,
  autologin,
  loginType,
  component: ComponentToRender,
  ...rest
}) => {
  const authPlayer = useAppSelector(selectPlayerWeak);
  const isAuthenticated = !!authPlayer;
  // const toRender = MetaWrapper(ComponentToRender);

  return (
    <TrackingRoute
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          // @ts-ignore
          <ComponentToRender {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { target: props.location, autologin, loginType },
            }}
          />
        )
      }
    />
  );
};

const roomRoutes: CustomRouteProps[] = [
  { path: '/debugAuth', component: MetaWrapper(BackendAuth) },
  { path: '/deeplink', component: DeepLink, autologin: true },
  { path: '/room/:roomId', component: Room },
  { path: '/cabinet', component: Cabinet, loginType: LoginType.CREATE_ROOM },
];

const commonRoutes: CustomRouteProps[] = [
  { path: '/', component: PandaHome, exact: true },
  // { path: '/', component: Main, exact: true },
  { path: '/play/:link', component: Link },
  { path: '/login', component: PandaLogin },
  { path: '/loginNew', component: LoginNew },
  { path: '/loginSocial', component: LoginSocial },
  { path: '/debug', component: DebugPage },
  { path: '/intro', component: SinglePage },
  { path: '/landing-1', component: LandingPage1 },
  { path: '/landing-2', component: LandingPage2 },
  { path: '/landing-3', component: LandingPage3 },
  { path: '/rooms/:setId?', component: MetaWrapper(RoomsPage) },
  // { path: `/${AUTH_CALLBACK_LOCATION}`, component: Login },
  // { path: '/mint', component: MintPage },
];

const App: React.FC = () => {
  const hasPlayer = useAppSelector(selectHasPlayer);
  const classes = containerStyle();

  return (
    <Container maxWidth="md" classes={{ ...classes }}>
      <ErrorBoundary>
        <AppService />
        <Router>
          <Switch>
            {commonRoutes.map((props, idx) => (
              <TrackingRoute key={idx} {...props} />
            ))}

            {roomRoutes.map((props, idx) => (
              <PrivateRoute key={idx} {...props} />
            ))}
          </Switch>
        </Router>
        {hasPlayer && <MenuPages />}
        <OrientalModal />
      </ErrorBoundary>
    </Container>
  );
};

export default App;
