import { IdType } from '@shared/generics';
import { ObjectType } from '@src/common/generics';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { selectLocale } from '@store/reducers/session.reducer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Banner, CardDeck, Creator, Game, Host, TopSetConfig } from '@graphql/queries';
import { RootState } from '@app/store';
import { LOCALE } from '@src/shared/common';
import { IActiveRoom } from '@src/shared/misc/room.fragment';
import { selectPlayerId, selectPlayedPartyIds, selectPlayerIdWeak, selectPlayerDecks } from './player.reducer';
import { MoneyReceiverServerEvent } from '@src/shared/socketEvents/socketEvents';
import { createSimpleDraftReducer, createSimpleReducer } from '../reduxHelpers';
import { Avatar } from '@src/shared/misc/roomFeatures.types';
import { SET_TYPES } from '@src/shared/homepage/configSet';

export enum VideoPlacement {
  SMALL,
  LARGE,
}

export type VideoState = {
  isVideoEnabled: boolean;
  isAudioEnabled: boolean;
  containerId: string;
  placement: VideoPlacement;
  shouldRestart: ObjectType;
};

export type VideoStateRequest = {
  shouldEnableAudio?: boolean;
  shouldEnableVideo?: boolean;
};

export interface VideoStateData {
  [playerId: string]: VideoState;
}

type VideoStatesPayload = PayloadAction<VideoStateData>;

export enum BLOCKCHAIN_TYPE {
  SOLANA = 'SOLANA',
  BSC = 'BSC',
}

export type ConfigRewards = {
  maxEnergy: number;
  friendlyBonus: number;
};

export interface IPlayerCard {
  playerId: IdType;
  avatar?: Partial<Avatar>;
  name: string;
  isStreamer: boolean;
}

export type CommonStoreState = {
  decks: CardDeck[];
  games: Game[];
  hosts: Host[];
  creators: Creator[];
  rooms: IActiveRoom[];
  banners: Banner[];
  topSetConfig: TopSetConfig;
  playerVideoStateRequest: VideoStateRequest;
  playersVideoState: {
    [playerId: string]: VideoState;
  };
  isWsConnected: boolean;
  blockchain: {
    minClaim: number;
    maxClaim: number;
    chainType: BLOCKCHAIN_TYPE;
    solana: {
      network: string;
      token: string;
      target: string;
    };
    bsc: {
      chainId: number;
      token: string;
      target: string;
    };
  };
  draw: {
    frameFrequency: number;
    liveDrawFrequency: number;
    sendFrequency: number;
    maxLinePoints: number;
  };
  rewards: ConfigRewards;
  received: {
    playerId: IdType;
    amount: number;
    balance: number;
    name?: string;
  };
  resetData: number;
  streamers: IPlayerCard[];
};

const initialStore: CommonStoreState = {
  decks: [],
  games: [],
  hosts: [],
  creators: [],
  rooms: [],
  banners: [],
  topSetConfig: {
    banners: [],
    topSet: [],
  },
  playerVideoStateRequest: {
    shouldEnableAudio: undefined,
    shouldEnableVideo: undefined,
  },
  playersVideoState: {},
  isWsConnected: false,
  blockchain: {
    minClaim: 0,
    maxClaim: 0,
    chainType: BLOCKCHAIN_TYPE.BSC,
    solana: {
      network: '',
      token: '',
      target: '',
    },
    bsc: {
      chainId: 0,
      token: '',
      target: '',
    },
  },
  draw: {
    frameFrequency: 1,
    liveDrawFrequency: 1,
    sendFrequency: 1,
    maxLinePoints: 50,
  },
  rewards: {
    maxEnergy: 5,
    friendlyBonus: 0.1,
  },
  received: {
    playerId: '',
    amount: 0,
    balance: 0,
  },
  resetData: 0,
  streamers: [],
};

const commonSlice = createSlice({
  name: 'common',
  initialState: initialStore,
  reducers: {
    setDecks: createSimpleReducer('decks'),
    setGamesArray: (state, action: PayloadAction<Game[]>) => {
      return {
        ...state,
        games: action.payload.filter((value) => value !== null),
      };
    },
    setChosenGame: (state, action: PayloadAction<{ gameId: IdType }>) => {
      state.games.find((game) => game.id === action.payload.gameId);
    },
    setGameBalance: (state, action: PayloadAction<{ gameId: IdType; dailyBalance: number }>) => {
      const game = state.games.find((game) => game.id === action.payload.gameId);
      game!.balance!.dailyBalance = action.payload.dailyBalance;
    },
    setGameBalances: (state, action: PayloadAction<{ id: IdType; balance: { dailyBalance: number } }[]>) => {
      state.games.forEach((game, index) => {
        const payload = action.payload[index];
        if (game.id === payload.id) {
          game.balance.dailyBalance = payload.balance.dailyBalance;
        }
      });
    },
    setRooms: (state, action: PayloadAction<IActiveRoom[]>) => {
      return {
        ...state,
        rooms: action.payload.filter((value) => value !== null),
      };
    },
    setHosts: (state, action: PayloadAction<Host[]>) => {
      return {
        ...state,
        hosts: action.payload.filter((value) => value !== null), //.sort(hostSort),
      };
    },
    setCreators: (state, action: PayloadAction<Creator[]>) => {
      return {
        ...state,
        creators: action.payload.filter((value) => value !== null),
      };
    },
    setBanners: (state, action: PayloadAction<Banner[]>) => {
      return {
        ...state,
        banners: action.payload.filter((value) => value !== null),
      };
    },
    setTopSetConfig: (state, action: PayloadAction<TopSetConfig>) => {
      return {
        ...state,
        topSetConfig: action.payload,
      };
    },
    setWSConnectStatus: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isWsConnected: action.payload,
      };
    },
    setVideoState: (state, action: PayloadAction<{ playerId: string; data: Partial<VideoState> }>) => {
      return {
        ...state,
        playersVideoState: {
          ...state.playersVideoState,
          [action.payload.playerId]: {
            ...state.playersVideoState[action.payload.playerId],
            ...action.payload.data,
          },
        },
      };
    },
    setVideoStates: (state, action: VideoStatesPayload) => {
      // TODO: get rid from immerse merge.
      _.merge(state.playersVideoState, action.payload);
      return state;
    },
    setVideoStateRequest: (state, action: PayloadAction<Partial<VideoStateRequest>>) => {
      return {
        ...state,
        playerVideoStateRequest: {
          ...state.playerVideoStateRequest,
          ...action.payload,
        },
      };
    },
    setBlockchainConfig: (state, action: PayloadAction<CommonStoreState['blockchain']>) => {
      return {
        ...state,
        blockchain: action.payload,
      };
    },
    setDrawConfig: (state, action: PayloadAction<CommonStoreState['draw']>) => {
      return {
        ...state,
        draw: action.payload,
      };
    },
    setRewardsConfig: (state, action: PayloadAction<ConfigRewards>) => {
      state.rewards = action.payload;
    },
    setResetDataConfig: (state, action: PayloadAction<number>) => {
      state.resetData = action.payload;
    },
    setReceivedInfo: (state, action: PayloadAction<MoneyReceiverServerEvent>) => {
      state.received = action.payload;
    },
    setStreamers: createSimpleDraftReducer('streamers'),
  },
});

export const {
  setDecks,
  setGamesArray,
  setChosenGame,
  setRooms,
  setHosts,
  setCreators,
  setBanners,
  setGameBalance,
  setGameBalances,
  setTopSetConfig,
  setWSConnectStatus,
  setVideoState,
  setVideoStates,
  setVideoStateRequest,
  setBlockchainConfig,
  setDrawConfig,
  setRewardsConfig,
  setReceivedInfo,
  setResetDataConfig,
  setStreamers,
} = commonSlice.actions;
export default commonSlice;

//const hostSort = (hostL: Host, hostR: Host) => hostL.priority - hostR.priority;

const commonState = (state: RootState) => state.common || initialStore;

export const getAllGames = createSelector(commonState, (state) => state.games);
export const getCreators = createSelector(commonState, (state) => state.creators);
export const selectAllHosts = createSelector(commonState, (state) => state.hosts);
export const getHosts = createSelector(commonState, selectLocale, (state, locale) =>
  state.hosts.filter((host) => host.locale === LOCALE.COMMON || host.locale === locale)
);
export const getRooms = createSelector(commonState, (state) => state.rooms);
export const selectRoomsNotOwned = createSelector(getRooms, selectPlayerIdWeak, (rooms, playerId) =>
  rooms.filter((room) => !room.owner || room.owner !== playerId)
);
export const selectDecks = createSelector(commonState, (state) => state.decks);
export const getBanners = createSelector(commonState, (state) => state.banners);
export const getTopSetConfig = createSelector(commonState, (state) => state.topSetConfig);
export const getHomepageBanners = createSelector(commonState, (state) => state.topSetConfig?.banners);
export const getTopSets = createSelector(commonState, (state) => state.topSetConfig?.topSet);

export const selectAllStreamers = createSelector(commonState, (state) => state.streamers);
export const selectStreamers = createSelector(selectAllStreamers, selectPlayerId, (streamers, playerId) =>
  streamers.filter((streamer) => streamer.playerId !== playerId)
);

export const selectFriendlyBonus = createSelector(commonState, (state) => state.rewards.friendlyBonus);

export const selectBlockchain = createSelector(commonState, (state) => state.blockchain);
export const selectBlockchainType = createSelector(selectBlockchain, (blockchain) => blockchain.chainType);

export const selectSolana = createSelector(selectBlockchain, (state) => state.solana);
export const selectSolanaNetwork = createSelector(selectSolana, (solana) => solana?.network || '');
export const selectSolanaToken = createSelector(selectSolana, (solana) => solana?.token || '');
export const selectSolanaTarget = createSelector(selectSolana, (solana) => solana?.target || '');

export const selectBsc = createSelector(selectBlockchain, (state) => state.bsc);

export const selectDrawConfig = createSelector(commonState, (state) => state.draw);
export const selectResetData = createSelector(commonState, (state) => state.resetData);

export const selectReceivedInfo = createSelector(commonState, (state) => state.received);

export const getTopSet = (setId: string) =>
  createSelector(commonState, (state) => state.topSetConfig.topSet.find((set) => set.id === setId));
export const selectRoomSetName = createSelector(
  getTopSets,
  selectLocale,
  (sets, locale) => sets.find((set) => set.type === SET_TYPES.ROOM_SET)?.name[locale]
);

export const getPlayersVideoState = createSelector(commonState, (state) => state.playersVideoState);

export const getIsWsConnected = createSelector(commonState, (state) => state.isWsConnected);

export const selectCurrentPlayerVideoState = createSelector(commonState, selectPlayerIdWeak, (state, playerId) =>
  playerId ? state.playersVideoState[playerId] || {} : ({} as VideoState)
);
export const selectPlayerVideoContainerId = (playerId: string) =>
  createSelector(getPlayersVideoState, (videoState) => videoState[playerId]?.containerId);

export const selectPlayerVideoState = (playerId: string) =>
  createSelector(getPlayersVideoState, (videoState) => videoState[playerId]);

export const selectPlayerVideoStatePlacement = (playerId: string) =>
  createSelector(selectPlayerVideoState(playerId), (videoState) => videoState.placement);

export const selectVideoStateRequest = createSelector(commonState, (state) => state.playerVideoStateRequest);

// helpers. we have to memoize them.
export const getGameRooms = (gameId: string) =>
  createSelector(getRooms, (rooms) => rooms.filter((room) => room.gameId === gameId));

export const getGameParties = (gameId: string) =>
  createSelector(getHosts, (hosts) => hosts.filter((host) => host.game.id === gameId));

export const selectPartyGame = (partyId: string) =>
  createSelector(selectAllHosts, (parties) => parties.find((party) => party.id === partyId)?.game);

export const getGamePartiesSorted = (gameId: string) =>
  createSelector(getGameParties(gameId), selectPlayedPartyIds, (parties, playedParties) =>
    _.sortBy(parties, [(party) => playedParties.includes(party.id), 'price'])
  );

export const selectPlayerDecksExtended = createSelector(selectDecks, selectPlayerDecks, (decks, deckIds) =>
  decks.filter((deck) => deckIds.includes(deck.id))
);

export const selectDeck = (deckId?: IdType) =>
  createSelector(selectDecks, (decks) => decks.find((deck) => deck.id === deckId));
