import React, { useEffect } from 'react';
import { Route, useHistory, RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { roomJoin } from '@store/thunk/room';
import { selectPartyId, selectIsGameOver, ROOM_STATE, selectRoomState } from '@store/room/roomSlice';
import { getIsCreateRoomLoading } from '@store/reducers/loadActions';
import { RoomLobby } from './roomLobby';
import { RoomGame } from './roomGame';
import { RoomService } from '@services/roomService';
import { useAppSelector } from '@app/hooks';
import FullRoomModal from '@components/Modals/FullRoomModal';
import { selectIsRoomFullShown } from '@store/reducers/popups';
import { RoomWrapper as RoomWrapperOld } from '@components/AppWrappers/RoomWrapper';
import { InviteModal } from '@components/Modals';
import { GlobalContextProvider } from '@src/services/globalContextProvider';
import { selectRatedParties } from '@src/store/reducers/player.reducer';
import { Notifications } from '@src/components/Notifications/notifications';
// import VideoChat from '@src/components/VideoChat';

// pandacards
import { PandaRoomSession } from './roomSession';
import PandaRoomWrapper from '@pc/pages/room/PandaRoomWrapper';
import { DoctorLobbyLayout } from '@pc/components/layout';

interface RouterProps {
  roomId: string;
}

interface Props extends RouteComponentProps<RouterProps> {}

const roomSubpaths = new Map<ROOM_STATE, string>([
  [ROOM_STATE.UNKNOWN, ''],
  [ROOM_STATE.LOBBY, '/lobby'],
  [ROOM_STATE.GAME, '/session'],
]);

// We need internal hook to get right redirections.
const Room: React.FC<Props> = ({ match, location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const isLoading = useSelector(getIsCreateRoomLoading);
  const roomState = useSelector(selectRoomState);
  const selectedPartyId = useAppSelector(selectPartyId);
  const isGameOver = useAppSelector(selectIsGameOver);
  const ratedParties = useAppSelector(selectRatedParties);
  const isRoomFullShown = useAppSelector(selectIsRoomFullShown);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const partyId = search.get('partyId') || '';
    const question = search.get('question') || '';
    dispatch(roomJoin({ isPublicRoom: false, roomId: match.params.roomId, partyId, question }));
  }, []);

  useEffect(() => {
    if (roomState === ROOM_STATE.UNKNOWN) {
      return;
    }

    const getNextScreen = () =>
      isGameOver && selectedPartyId && !ratedParties.includes(selectedPartyId) ? 'rateScreen' : 'nextParty';

    // console.log('ROOM STATE', roomState);

    if (roomState === ROOM_STATE.LOBBY && location.pathname.includes('/session') && selectedPartyId) {
      const nextScreen = getNextScreen();
      history.replace(`/room/${match.params.roomId}/lobby/${nextScreen}/${selectedPartyId}`);
      return;
    }

    if (roomState !== ROOM_STATE.LOBBY || !location.pathname.includes('/lobby')) {
      history.replace(`/room/${match.params.roomId}${roomSubpaths.get(roomState)}`, history.location.state);
      return;
    }
  }, [roomState]);

  const pandacards = true;

  return (
    <GlobalContextProvider>
      {/* PANDACARDS */}
      {pandacards ? (
        <PandaRoomWrapper>
          <Route path="/room/:roomId/session" component={PandaRoomSession} />
          <Route path="/room/:roomId/lobby" component={DoctorLobbyLayout} />
        </PandaRoomWrapper>
      ) : (
        // FUN CHAT
        <RoomWrapperOld showVideoChat={true}>
          <RoomService roomId={match.params.roomId} />
          <Route path="/room/:roomId/game" component={RoomGame} />
          <Route path="/room/:roomId/lobby" component={RoomLobby} />
          <Notifications />
          <InviteModal />
        </RoomWrapperOld>
      )}

      <FullRoomModal isOpen={isRoomFullShown} />
    </GlobalContextProvider>
  );
};

export { Room };
