import { useAppSelector } from '@src/app/hooks';
import { AppDispatch } from '@src/app/store';
import { LOCALE } from '@src/shared/common';
import { IString } from '@src/shared/misc/string.types';
import { selectLocale, setLocale, setSessionState } from '@store/reducers/session.reducer';

export const setLocaleThunk = (locale: LOCALE) => (dispatch: AppDispatch) => {
  localStorage.setItem('funnchat_locale', locale);
  dispatch(setLocale(locale));
};

export const useLocaleString = (lString: IString) => {
  const locale = useAppSelector(selectLocale);
  return lString[locale];
};

/*export const sendCardsDealingThunk = (data: any) => (dispatch: AppDispatch, getState: () => RootState) => {

}*/
