export enum GameTypes {
  COMING_SOON = 'COMING_SOON',
  DRINK_ROULETTE = 'DRINK_ROULETTE',
  TWENTY_QUESTIONS = 'TWENTY_QUESTIONS',
  ROAST = 'ROAST',
  LOVE_BOTTLE = 'LOVE_BOTTLE',
  MATCH_ME = 'MATCH_ME',
  GUESS_ME = 'GUESS_ME',
  QUIZ_BATTLE = 'QUIZ_BATTLE',
  DRAW_AND_GUESS = 'DRAW_AND_GUESS',
  ROAST_BATTLE = 'ROAST_BATTLE',
  QUIZ_CLASSIC = 'QUIZ_CLASSIC',
  CHESTS = 'CHESTS',
  FAKE_ARTIST = 'FAKE_ARTIST',
  QUESTION_CHALLENGE = 'QUESTION_CHALLENGE',
  HELP_SCREEN = 'HELP_SCREEN',
  HILLBILLY = 'HILLBILLY',
  UNITY_GAME = 'UNITY_GAME',
  SEND_QUESTION = 'SEND_QUESTION',
  ALIAS = 'ALIAS',
  GUESS_UP = 'GUESS_UP',
}

export enum GAME_LAYOUT {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
  STRETCH = 'STRETCH',
}

// Deprecated
export const gameTitles = {
  [GameTypes.DRINK_ROULETTE]: 'Drink Roulette',
  [GameTypes.TWENTY_QUESTIONS]: 'Twenty Questions',
  [GameTypes.ROAST]: 'Roast',
  [GameTypes.LOVE_BOTTLE]: 'Love Bottle',
  [GameTypes.MATCH_ME]: 'Match Me',
  [GameTypes.GUESS_ME]: 'Guess Me',
  [GameTypes.QUIZ_BATTLE]: 'Quiz Battle',
  [GameTypes.COMING_SOON]: 'Coming Soon',
  [GameTypes.ROAST_BATTLE]: 'Roast Battle',
  [GameTypes.QUIZ_CLASSIC]: 'Quiz Classic',
  [GameTypes.CHESTS]: 'Chests',
  [GameTypes.FAKE_ARTIST]: 'Fake Artist',
  [GameTypes.QUESTION_CHALLENGE]: 'One Question Challenge',
  [GameTypes.HILLBILLY]: 'Hillbilly Racing',
  [GameTypes.UNITY_GAME]: 'Unity',
};

export const isRouletteGame = (gameType: GameTypes) =>
  [GameTypes.DRINK_ROULETTE, GameTypes.TWENTY_QUESTIONS, GameTypes.ROAST].includes(gameType);

export const isOneMinimumPlayer = (gameType: GameTypes) => getMinimumPlayers(gameType) === 1;

export const isSingleGame = (gameType: GameTypes) => [GameTypes.SEND_QUESTION].includes(gameType);
export const hasBalanceGame = (gameType: GameTypes) => ![GameTypes.SEND_QUESTION].includes(gameType);
export const hasFriendBonusGame = (gameType: GameTypes) => ![GameTypes.CHESTS].includes(gameType);

export const getMinimumPlayers = (gameType: GameTypes) => {
  switch (gameType) {
    case GameTypes.MATCH_ME:
    case GameTypes.DRAW_AND_GUESS:
    case GameTypes.CHESTS:
    case GameTypes.QUESTION_CHALLENGE:
    case GameTypes.SEND_QUESTION:
      return 1;
    case GameTypes.FAKE_ARTIST:
    case GameTypes.ROAST_BATTLE:
      return 3;
    default:
      return 2;
  }
};
