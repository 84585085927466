import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const HomepageBarStyles = makeStyles((theme) => ({
  iconWrapper: {
    padding: '10px 0',
    [theme.breakpoints.up('sm')]: { marginRight: 7 },

    '&.hasNotifications': {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '7px',
        right: '25px',
        width: '11px',
        height: '11px',
        borderRadius: '50%',
        backgroundColor: '#F83E67',
        [theme.breakpoints.up('sm')]: {
          right: '15px',
        },
      },
    },

    '& > img': {
      cursor: 'pointer',
    },
  },

  icon: {
    width: 31,
    height: 31,
    marginRight: 25,
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      marginRight: 20,
    },
  },

  logo: {
    width: 76,
    height: 31,
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      marginRight: 20,
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 15,
    },
  },

  navigationBarContainer: {
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 15px 0 7px',
    boxSizing: 'border-box',
    backgroundColor: '#FFF',
    color: '#1B2036',
    position: 'relative',
    overflow: 'hidden',
    '&::after': {
      content: '""',
      display: 'block',
      height: '1px',
      backgroundColor: '#E8E9EB',
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute',
    },
    [theme.breakpoints.up('sm')]: {
      height: '60px',
      padding: '0px 20px 0 8px',
      borderLeft: '1px solid rgba(232, 233, 235, 1)',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 25px 0 13px',
      flexBasis: '30%',
    },
  },

  arrowWrapper: {
    width: '18px',
    height: '14px',
    flexShrink: 0,
    marginRight: '17px',
    cursor: 'pointer',
    padding: '10px 8px',
    transition: 'all .23s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    [theme.breakpoints.up('sm')]: {
      padding: '14px 12px',
      marginRight: '13px',
    },
    '&:hover': {
      backgroundColor: '#EAF1FE',
      '& svg path': {
        fill: '#2C74F3',
      },
    },

    '&:active': {
      backgroundColor: 'transparent',
      '& svg path': {
        fill: '#164BA8',
      },
    },
  },

  title: {
    fontFamily: fontFamily,
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '22px',
    color: '#1B2036',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '24px',
    },
  },

  menuBarContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    position: 'relative',
    height: '48px',
    boxSizing: 'border-box',
    flexShrink: 0,
    padding: '0 15px',
    borderTop: '1px solid rgba(232, 233, 235, 1)',
    borderBottom: '1px solid rgba(232, 233, 235, 1)',
    [theme.breakpoints.up('sm')]: {
      height: '60px',
      padding: '0 30px 0 25px',
      border: 0,
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 25px 0 30px',
    },
  },

  statusBarContainer: {
    fontFamily: fontFamily,
    display: 'flex',
    height: '44px',
    alignItems: 'center',
    padding: '0 15px',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    color: '#1B2036',
    width: '100%',
    borderLeft: '1px solid #E8E9EB',
    boxSizing: 'border-box',
    position: 'relative',
    overflow: 'hidden',
    '&::after': {
      content: '""',
      display: 'block',
      height: '1px',
      backgroundColor: '#E8E9EB',
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 20px',
      height: '60px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 25px',
      flexBasis: '70%',
      overflow: 'hidden',
    },
  },

  statusBarActions: {
    color: 'inherit',
  },
}));
