export enum CLIENT_EVENTS {
  PLAYER_UPDATED_GAME_STATE = 'PLAYER_UPDATED_GAME_STATE',
  PLAYER_JOINED_ROOM = 'PLAYER_JOINED_ROOM',
  PLAYER_LEFT_ROOM = 'PLAYER_LEFT_ROOM',
  PLAYER_LEFT_GAME = 'PLAYER_LEFT_GAME',
  PLAYER_KICKED_FROM_ROOM = 'PLAYER_KICKED_FROM_ROOM',
  PLAYER_END_GAME = 'PLAYER_END_GAME',
  // PLAYER_SELECTED_HOST = 'PLAYER_SELECTED_HOST',
  PLAYER_STARTED_GAME = 'PLAYER_STARTED_GAME',
  PLAYER_READY_TO_JOIN_GAME = 'PLAYER_READY_TO_JOIN_GAME',
  PLAYER_READY_TO_QUIT_GAME = 'PLAYER_READY_TO_QUIT_GAME',
  PLAYER_REWARD_REQUEST = 'PLAYER_REWARD_REQUEST',
  // PLAYER_READY_TO_PLAY = 'PLAYER_READY_TO_PLAY',

  PLAYER_CHANGED_NAME = 'PLAYER_CHANGED_NAME',
  PLAYER_UPDATED_ROOM = 'PLAYER_UPDATED_ROOM',
  PLAYER_CHANGED_AVATAR = 'PLAYER_CHANGED_AVATAR',
  PLAYER_TIMER_CHANGED = 'PLAYER_TIMER_CHANGED',
  PLAYER_CHANGED_BACKGROUND = 'PLAYER_CHANGED_BACKGROUND',
  PLAYER_SENT_STICKER = 'PLAYER_SENT_STICKER',
  PLAYER_SENT_MONEY = 'PLAYER_SENT_MONEY',
  PLAYER_CHANGED_ROOM_PUBLICITY = 'PLAYER_CHANGED_ROOM_PUBLICITY',

  ROUND_STARTED = 'ROUND_STARTED',
  PLAYER_VOTED = 'PLAYER_VOTED',
  PLAYER_STAKED = 'PLAYER_STAKED',
  PLAYER_PRE_VOTED = 'PLAYER_PRE_VOTED',
  PLAYER_READY = 'PLAYER_READY',
  PLAYER_FINISHED = 'PLAYER_FINISHED',
  PLAYER_STARTED_VOTE = 'PLAYER_STARTED_VOTE',
  PLAYER_DREW = 'PLAYER_DREW',

  PLAYER_UNITY_START = 'PLAYER_UNITY_START',
  PLAYER_UNITY_FINISH = 'PLAYER_UNITY_FINISH',

  GAME_STATE_REQUESTED = 'GAME_STATE_REQUESTED',

  PLAYER_LIGHT_EVENT = 'PLAYER_LIGHT_EVENT',
  PLAYER_HEAVY_EVENT = 'PLAYER_HEAVY_EVENT',

  // Pandacards specific events
  PLAYER_UPDATED_CARD_DECK = 'PLAYER_UPDATED_CARD_DECK',

  PLAYER_MADE_QUESTION = 'PLAYER_MADE_QUESTION',
  PLAYER_DEALING_CARDS = 'PLAYER_DEALING_CARDS',
  PLAYER_DEALED_CARDS = 'PLAYER_DEALED_CARDS',
  PLAYER_TOOK_CARDS = 'PLAYER_TOOK_CARDS',
  PLAYER_FLIPPED_CARD = 'PLAYER_FLIPPED_CARD',
  PLAYER_ENLARDED_CARD = 'PLAYER_ENLARDED_CARD',
  PLAYER_CHANGED_WORD = 'PLAYER_CHANGED_WORD',
  PLAYER_ENABLED_DRAG = 'PLAYER_ENABLED_DRAG',
  PLAYER_DRAGGING_CARD = 'PLAYER_DRAGGING_CARD',
  PLAYER_DRAGGED_CARD = 'PLAYER_DRAGGED_CARD',
  PLAYER_COMBINED_CARD = 'PLAYER_COMBINED_CARD',
  PLAYER_DISCARDED_CARD = 'PLAYER_DISCARDED_CARD',
  PLAYER_DISCARDED_ALL = 'PLAYER_DISCARDED_ALL',
  PLAYER_WITHDRAWED_CARD = 'PLAYER_WITHDRAWED_CARD',
}

export enum SERVER_EVENTS {
  ROOM_JOIN = 'ROOM_JOIN',
  ROOM_STATE_UPDATED = 'ROOM_STATE_UPDATED',
  ROOM_REMOVED = 'ROOM_REMOVED',
  GAME_STATE_UPDATED = 'GAME_STATE_UPDATED',
  SESSION_STATE_UPDATED = 'SESSION_STATE_UPDATED',
  SESSION_STATE_PARTIAL_UPDATED = 'SESSION_STATE_PARTIAL_UPDATED',
  GAME_STATE_UPDATED_DEFAULT = 'GAME_STATE_UPDATED_DEFAULT',
  GAME_DRAW = 'GAME_DRAW',
  TICKETS_RAN_OUT_DUMMY = 'TICKETS_RAN_OUT_DUMMY',
  PLAYER_NAME_CHANGED = 'PLAYER_NAME_CHANGED',
  PLAYER_STICKER_SENT = 'PLAYER_STICKER_SENT',
  PLAYER_MONEY_SENT = 'PLAYER_MONEY_SENT',
  PLAYER_MONEY_RECIEVED = 'PLAYER_MONEY_RECIEVED',
  PLAYER_MONEY_DEPOSITED = 'PLAYER_MONEY_DEPOSITED',
  BALANCE_UPDATE = 'BALANCE_UPDATE',
  QUEST_UPDATE = 'QUEST_UPDATE',
  QUEST_ADD = 'QUEST_ADD',

  PLAYER_UNITY_START = 'PLAYER_UNITY_START',
  PLAYER_UNITY_FINISH = 'PLAYER_UNITY_FINISH',

  PAYMENT = 'PAYMENT',

  DEBUG_LIGHT_EVENT = 'DEBUG_LIGHT_EVENT',
  DEBUG_HEAVY_EVENT = 'DEBUG_HEAVY_EVENT',

  PLAYER_CARD_DECK_UPDATED = 'PLAYER_CARD_DECK_UPDATED',
}

export enum SYSTEM_EVENTS {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  RECONNECT = 'reconnect',
}
