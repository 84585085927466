import { makeStyles } from '@material-ui/core';
import pandaColors from '@pc/helpers/colors';

export const styles = makeStyles((theme) => ({
  LostConnection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: pandaColors.black._800,
    borderRadius: '10px',
    '&.isClient': {
      // client mobile
      [theme.breakpoints.down('xs')]: {
        '& .LostConnection__wrapper': {
          width: 'auto',
          '& > img': {
            width: '200px',
          },
          '& > h2': {
            fontSize: '20px',
            marginBottom: '20px',
          },
          '& > p': {
            fontSize: '16px',
            lineHeight: '21px',
            marginBottom: '10px',
            padding: '0 10px',
          },
        },
      },
    },
  },

  LostConnection__wrapper: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '480px',

    '& > img': {
      width: '300px',
      alignSelf: 'center',
      marginBottom: '25px',
    },

    '& > h2': {
      fontSize: '32px',
      fontWeight: 600,
      marginTop: 0,
      marginBottom: '15px',
      letterSpacing: '0.5px',
    },

    '& > p': {
      fontSize: '18px',
      lineHeight: '27px',
      fontWeight: 300,
      textAlign: 'center',
      marginBottom: '25px',
    },
  },

  LostConnection__timer: {
    alignSelf: 'center',
    marginTop: '25px',
    '& > span': {
      width: '45px',
      height: '40px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 600,
      fontSize: '24px',
      borderRadius: '5px',
      border: `1px solid ${pandaColors.black._650}`,
      backgroundColor: pandaColors.black._900,
      '&:first-child': {
        marginRight: '2px',
      },
    },
  },
}));
