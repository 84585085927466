import React from 'react';
import { CommonModal } from '@components/Modals';
import Button from '@components/Button';
import { styles } from './styles';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import coins from '@art/images/modals/coins.png';
import close from '@art/images/modals/close.svg';
import { mutateGraphql } from '@src/library/fetch';
import { addRewardDebug } from '@graphql/debugQueries';
import { selectPlayerId } from '@src/store/reducers/player.reducer';
import { requestRewards } from '@src/graphql/player.queries';
import CloseCross from '@src/components/CloseCross/CloseCross';

interface Props {
  isOpen: boolean | undefined;
  handleClose: () => void;
}

const BuyCoinModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const dispatch = useAppDispatch();
  const playerId = useAppSelector(selectPlayerId);

  const onAddRewardDebug = () => {
    mutateGraphql(addRewardDebug, { playerId, amount: 100 }).then((data) => {
      dispatch(requestRewards);
    });
  };

  const onClick = () => {
    onAddRewardDebug();
    handleClose();
  };

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose}>
      <div className={classes.wrapper}>
        <CloseCross onClick={handleClose} />
        <h3 id="transition-modal-title">{isEnLocale ? 'Not enough token' : 'Недостаточно токенов'}</h3>

        <div className={classes.content}>
          <p>{isEnLocale ? 'You can buy more tokens right now' : 'Ты можешь купить больше токенов прямо сейчас'} </p>
          <div className="btnContainer">
            <img src={coins} alt="" />
            <Button type="primary-green-new" text={isEnLocale ? 'Buy $1.99' : 'За $1.99'} onClick={onClick} />
          </div>
        </div>
        {/* <p>{isEnLocale ? 'or' : 'или'}</p>
        <Button
          type="primary-blue-new"
          text={isEnLocale ? 'Go to store' : 'В магазин'}
          onClick={() => { }}
        // disabled={}
        /> */}
      </div>
    </CommonModal>
  );
};

export default BuyCoinModal;
