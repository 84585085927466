import { ScrollContainer } from '@pc/components/layout';
import { MixProps } from '@src/common/generics';
import { PersonItemBig } from '../../common/VideoChat/parts/PersonItem/PersonItemVariants';
import ImagineQuestion from './ImagineQuestion/ImagineQuestion';
import { styles } from './ClientLayout.styles';

type TProps = {
  question: string;
  content?: React.ReactNode;
};

const ClientLayout: React.FC<MixProps<TProps>> = (props) => {
  const classes = styles();

  return (
    <ScrollContainer>
      <main className={classes.ClientLayout}>
        {/* game top content for client */}
        <section className={classes.ClientLayout__top}>
          <ImagineQuestion sessionQuestion={props.question} />
        </section>

        {/* center content */}
        <section className={classes.ClientLayout__center}>
          <div style={{ flexGrow: 1, position: 'relative', zIndex: 1 }}>
            {/* game center content for client */}
            {props.content}

            {/* doctor big video absoluted inside */}
            <PersonItemBig />
          </div>
        </section>
      </main>
    </ScrollContainer>
  );
};

export default ClientLayout;
